import { Box, Center, Flex, SimpleGrid, Spinner } from '@chakra-ui/react'
import { QueryErrorResetBoundary } from '@tanstack/react-query'
import { BreadcrumbComponent, ErrorPage, NotAuthorizedPage } from 'components'
import Sidebar from 'layout/Sidebar'
import { useAuth } from 'oidc-react'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Outlet } from 'react-router-dom'

const Layout = ({ roles }) => {
	const { userData } = useAuth()
	const isAuthorized = roles.some((item) =>
		userData.profile['sub.role'] ? userData.profile['sub.role']?.includes(item) : userData.profile.role?.includes(item)
	)

	return (
		<Flex>
			{isAuthorized && (
				<>
					<Sidebar />
					<Box flex={1} padding='4' paddingTop='0' h='100vh' overflowX='auto'>
						<BreadcrumbComponent />
						<QueryErrorResetBoundary>
							{({ reset }) => (
								<ErrorBoundary FallbackComponent={ErrorPage} onReset={reset}>
									<Suspense
										fallback={
											<SimpleGrid placeItems='center' height='90%'>
												<Spinner thickness='4px' speed='0.4s' color='blue.400' size='xl' />
											</SimpleGrid>
										}>
										<Outlet />
									</Suspense>
								</ErrorBoundary>
							)}
						</QueryErrorResetBoundary>
					</Box>
				</>
			)}
			{!isAuthorized && (
				<Center height='100vh' width='100vw'>
					<NotAuthorizedPage />
				</Center>
			)}
		</Flex>
	)
}

export default Layout
