import { SearchIcon } from '@chakra-ui/icons'
import { CardHeader, Input, InputGroup, InputLeftElement, Spinner, useColorModeValue } from '@chakra-ui/react'
import classNames from 'classnames'
import { useEffect, useRef } from 'react'
import { useSearchParams } from 'react-router-dom'

const fetching = 'fetching'

export const MainTableHeader = ({ placeholder = 'Search', render = null, fetchStatus = 'idle' }) => {
	const colorMode = useColorModeValue('#E2E8F0', '#FFFFFF29')
	const debounceRef = useRef(null)
	const [searchParams, setSearchParams] = useSearchParams()

	const search = searchParams.get('q') ?? ''

	useEffect(() => {
		if (search.length > 0) {
			return
		}

		setSearchParams((prev) => {
			prev.delete('q')
			return prev
		})
	}, [search, setSearchParams])

	const handleSearch = (e) => {
		clearTimeout(debounceRef.current)
		debounceRef.current = setTimeout(() => {
			setSearchParams((prev) => {
				prev.set('q', e.target.value)
				return prev
			})
		}, 500)
	}

	return (
		<CardHeader
			display='flex'
			justifyContent='space-between'
			alignItems='center'
			position='sticky'
			top={0}
			bg='inherit'
			zIndex={2}
			borderTopRadius='md'
			borderBottom={classNames('1px solid', colorMode)}>
			<InputGroup w='80' size='sm'>
				<InputLeftElement>{fetchStatus === fetching ? <Spinner size='sm' /> : <SearchIcon />}</InputLeftElement>
				<Input
					_focusVisible={{ border: '1px solid #63b3ed' }}
					borderRadius='full'
					type='search'
					placeholder={placeholder}
					defaultValue={search}
					onChange={handleSearch}
				/>
			</InputGroup>
			{!!render && render()}
		</CardHeader>
	)
}
