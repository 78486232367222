export const fetchMethods = {
	GET: 'GET',
	POST: 'POST',
	PUT: 'PUT',
	PATCH: 'PATCH',
	DELETE: 'DELETE',
}

export const pageTitles = {
	DASHBOARD: 'DASHBOARD',
	USERS: 'USERS',
	PARTNERS: 'PARTNERS',
	PARTNER_DETAILS: 'PARTNER DETAILS',
	HOSPITALS: 'HOSPITALS',
	DEVICES: 'DEVICES',
	DEVICE_DETAILS: 'DEVICE DETAILS',
	DEVIGE_LOGS: 'DEVICE LOGS',
	OS_DEPLOYMENTS: 'OS DEPLOYMENTS',
	OS_CREATE_DEPLOYMENT: 'CREATE DEPLOYMENT',
	OS_DEPLOYMENTS_DETAILS: 'OS DEPLOYMENT DETAILS',
	DEVICE_FLAVOUR: 'DEVICE FLAVOUR',
	DEVICE_APPS: 'DEVICE APPS',
	DEVICE_APPS_DETAILS: 'DEVICE APPS DETAILS',
	PROFILES: 'PROFILES',
	PROFILE_DETAILS: 'PROFILE DETAILS',
	CREATE_PROFILE: 'CREATE PROFILE',
	EDIT_PROFILE: 'EDIT PROFILE',
	HOSPITAL_FLOORS: 'HOSPITAL FLOORS',
	OS_RELEASES: 'OS RELEASES',
	APP_RELEASES: 'APP RELEASES',
	UPLOAD_ML_MODELS: 'UPLOAD ML MODELS',
	CREATE_ML_MODELS: 'CREATE ML MODELS',
	CREATE_TEAM_CONFIG: 'CREATE TEAM CONFIG',
	ML_DEPLOYMENTS: 'ML DEPLOYMENTS',
	APP_DEPLOYMENTS: 'APP DEPLOYMENTS',
	APP_DEPLOYMENTS_DETAILS: 'APP DEPLOYMENTS DETAILS',
	CREATE_APP_DEPLOYMENT: 'CREATE APP DEPLOYMENT',
}

export const userRoles = {
	SUPER_USER: 'hac.admin',
	DEVELOPER: 'hac.developer',
	SUPPORT: 'hac.support',
	PARTNER: 'hac.partner',
	HOSPITAL: 'hac.client',
	ADMIN: 'Admin',
	USER_PARTNER: 'Partner',
	CLIENT: 'Client',
}

export const navigationRoutes = {
	DASHBOARD: '/',
	USERS: '/users',
	PARTNERS: '/partners',
	HOSPITALS: '/hospitals',
	DEVICES: '/devices',
	DEVICE_LOGS: '/devicelogs',
	DEVICES_UPLOAD: '/devicesupload',
	PROFILES: '/profiles',
	HOSPITAL_FLOORS: '/hospital-floors',
	OS_DEPLOYMENTS: '/osdeployments',
	APP_DEPLOYMENTS: '/appdeployments',
	FLAVOUR: '/device-flavour',
	APPS: '/device-apps',
	OS_RELEASES: '/os-releases',
	APP_RELEASES: '/app-releases',
}

//Device
export const deviceTable = {
	deviceList: 'deviceList',
}

export const deviceDetailsCommands = {
	activeOfflineCommands: 'activeOfflineCommands',
}

export const deviceLogs = {
	allowList: 'allowList',
	commandLogs: 'commandLogs',
	liveConferences: 'liveConferences',
	pastConferenes: 'pastConferences',
	statusLogs: 'statusLogs',
	receivedLogs: 'receivedLogs',
	deviceLogsList: 'deviceLogsList',
	deviceLogsType: 'deviceLogsType',
}

export const deviceDetails = {
	deviceDetails: 'deviceDetails',
	treeView: 'treeView',
}

export const dashboard = {
	dashboardList: 'dashboardList',
}

//Users

export const usersTable = {
	usersList: 'usersList',
}

//Partners
export const partnersTable = {
	partnersList: 'partnersList',
}

export const partnerDetails = {
	partnerDetails: 'partnerDetails',
	partnersHospitals: 'partnersHospitals',
	mainOrganization: 'mainOrganization',
}

//Hospitals

export const hospitalsTable = {
	hospitalsList: 'hospitalsList',
}

export const hospitalsFLoorsTable = {
	hospitalFloors: 'hospitalFloors',
	hospitalPartnerFloors: 'hospitalPartnerFloors',
}

//OsDeployments

export const osDeploymentsTable = {
	osDeploymentsList: 'osDeploymentsList',
}

//DeviceFlavours

export const deviceFlavoursTable = {
	deviceFlavoursList: 'deviceFlavoursList',
}

export const deviceFlavourDetails = {
	deviceFlavourDetails: 'deviceFlavourDetails',
	deviceFlavourApps: 'deviceFlavourApps',
}

//DeviceApps

export const deviceAppsTable = {
	deviceAppsList: 'deviceAppsList',
}
export const deviceAppsDetails = {
	deviceAppsDetails: 'deviceAppsDetails',
	soloDeviceApp: 'soloDeviceApp',
}

//Profiles

export const profilesTable = {
	profilesList: 'profilesList',
}

export const profilesDetails = {
	profilesDetails: 'profilesDetails',
}

export const teamTypeId = {
	4: 'Health System',
	5: 'Region',
	6: 'Hospital',
	7: 'Department',
	8: 'Floor',
}

export const configurationTypes = {
	1: 'Wifi Band',
	2: 'Ntp',
	3: 'Ml Model',
	4: 'Bandwith',
}

export const deviceDeploymentStatus = {
	0: 'Open',
	1: 'Deploying',
	2: 'Deployed',
	3: 'Failed',
	4: 'Cancelled',
	5: 'Ignored',
	6: 'Check Update',
	7: 'Download Started',
	8: 'Download Failed',
	9: 'Download Finished',
	10: 'Download Stop',
	11: 'Install Started',
	12: 'Install Failed',
	13: 'Install Finished',
	14: 'Deployment Cancelled',
}

export const deviceDeploymentStatusColors = {
	0: '#08C4B0',
	1: '#D5900F',
	2: '#66D50F',
	3: '#FF0000',
	4: '#FF0000',
	5: '#FF0000',
	6: '#08C4B0',
	7: '#08C4B0',
	8: '#FF0000',
	9: '#66D50F',
	10: '#FF0000',
	11: '#66D50F',
	12: '#FF0000',
	13: '#08C4B0',
	14: '#FF0000',
}

export const deploymentJobStatus = {
	1: 'Cancelled',
	2: 'Pending',
	3: 'In Progress',
	4: 'Deployed',
	5: 'Failed',
	6: 'Partially Deployed',
}

export const deploymentJobStatusId = {
	CANCELLED: 1,
	PENDING: 2,
	IN_PROGRESS: 3,
	DEPLOYED: 4,
	FAILED: 5,
	PARTIALLY_DEPLOYED: 6,
}

export const osDeploymentColorSchemes = {
	Cancelled: 'red',
	Pending: 'gray',
	Deployed: 'green',
	Failed: 'orange',
	'In Progress': 'linkedin',
	'Partially Deployed': 'teal',
}

export const appDeploymentGroupStatus = {
	0: 'Open',
	1: 'Deploying',
	2: 'Deployed',
	3: 'Partially Deployed',
	4: 'Failed',
	5: 'Cancelled',
}

export const appDeploymentGroupStatusId = {
	OPEN: 0,
	DEPLOYING: 1,
	DEPLOYED: 2,
	PARTIALLY_DEPLOYED: 3,
	FAILED: 4,
	CANCELLED: 5,
}

export const appDeploymentGroupStatusColors = {
	0: '#08C4B0',
	1: '#D5900F',
	2: '#66D50F',
	3: '#08C4B0',
	4: '#FF0000',
	5: '#FF0000',
}

export const companyType = {
	STANDALONE: 'StandaloneCompany',
	MAIN: 'MainCompany',
	SUB: 'SubCompany',
}

export const deviceDeploymentLogs = {
	0: 'OPEN',
	1: 'DEPLOYING',
	2: 'DEPLOYED',
	3: 'FAILED',
	4: 'CANCELLED',
	5: 'IGNORED',
	6: 'CHECK_UPDATE',
	7: 'DOWNLOAD_START',
	8: 'DOWNLOAD_FAILED',
	9: 'DOWNLOAD_FINISHED',
	10: 'DOWNLOAD_STOP',
	11: 'INSTALL_STARTED',
	12: 'INSTALL_FAILED',
	13: 'INSTALL_FINISHED',
	14: 'DEPLOYMENT_CANCELLED',
	15: 'INSTALL_PENDING',
}

export const osDeploymentStatuses = {
	OPEN: 0,
	DEPLOYING: 1,
	DEPLOYED: 2,
	FAILED: 3,
	CANCELLED: 4,
	IGNORED: 5,
	CHECK_UPDATE: 6,
	DOWNLOAD_START: 7,
	DOWNLOAD_FAILED: 8,
	DOWNLOAD_FINISHED: 9,
	DOWNLOAD_STOP: 10,
	INSTALL_STARTED: 11,
	INSTALL_FAILED: 12,
	INSTALL_FINISHED: 13,
	DEPLOYMENT_CANCELLED: 14,
	INSTALL_PENDING: 15,
}

export const DeviceModes = {
	BED_SIDE: 1,
	ROOM_SIGN: 2,
	TV: 3,
	WHITEBOARD: 4,
	HELLO: 5,
	NURSE_STATION: 6,
}
